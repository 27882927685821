var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-navbar" }, [
    _c("div", { staticClass: "background" }, [
      _c("img", { attrs: { src: require("@/assets/header-bg.png") } })
    ]),
    _c("div", { staticClass: "fixed", attrs: { id: "nav-header" } }, [
      _c("div", { staticClass: "fixed-content" }, [
        _c(
          "div",
          { staticClass: "header-title" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "home" } } },
              [
                _c("el-image", {
                  staticClass: "logo-image",
                  attrs: { src: require("@/assets/hnlogo.svg") }
                })
              ],
              1
            ),
            _c(
              "el-input",
              {
                staticClass: "search",
                attrs: { placeholder: "搜索" },
                model: {
                  value: _vm.searchV,
                  callback: function($$v) {
                    _vm.searchV = $$v
                  },
                  expression: "searchV"
                }
              },
              [
                _c("template", { slot: "prefix" }, [
                  _c(
                    "span",
                    {
                      staticClass: "search-prefix",
                      staticStyle: {
                        "line-height": "40px",
                        "margin-left": "21px",
                        color: "white"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-search" })]
                  )
                ])
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "menu-container" },
          [
            _c(
              "el-menu",
              {
                staticClass: "menu",
                attrs: {
                  "default-active": _vm.activeIndex,
                  mode: "horizontal"
                },
                on: { select: _vm.handleSelect }
              },
              [
                _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("首页")]),
                _c(
                  "el-menu-item",
                  {
                    class: { "is-hover": _vm.dropdownVisible },
                    attrs: { index: "2" }
                  },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: {
                          command: _vm.handleCommand,
                          "visible-change": function(val) {
                            this$1.dropdownVisible = val
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "user" }, [_vm._v("星服务")]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.menus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-menu-item",
                  {
                    class: { "is-hover": _vm.businessDropdownVisible },
                    attrs: { index: "6" }
                  },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: {
                          command: _vm.handleCommand,
                          "visible-change": function(val) {
                            this$1.businessDropdownVisible = val
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "user" }, [
                          _vm._v("业务说明")
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.businessMenus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-menu-item",
                  {
                    class: { "is-hover": _vm.rightDropdownVisible },
                    attrs: { index: "7" }
                  },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "dropdown-class",
                        attrs: { placement: "bottom" },
                        on: {
                          command: _vm.handleCommand,
                          "visible-change": function(val) {
                            this$1.rightDropdownVisible = val
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "user" }, [
                          _vm._v("维权服务")
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "dropdown-class",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown"
                          },
                          _vm._l(_vm.rightMenus, function(menu) {
                            return _c(
                              "el-dropdown-item",
                              {
                                key: menu.caption,
                                attrs: { command: menu.command }
                              },
                              [_vm._v(_vm._s(menu.caption))]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("el-menu-item", { attrs: { index: "4" } }, [
                  _vm._v("新闻中心")
                ]),
                _c("el-menu-item", { attrs: { index: "5" } }, [
                  _vm._v("政策法规")
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "fixed-bottom" }, [
      _vm.navbar.menuTop
        ? _c("div", { staticClass: "scroll-banner" }, [
            _c(
              "div",
              { staticClass: "web-banner" },
              [
                _c(
                  "el-carousel",
                  {
                    staticClass: "carousel",
                    attrs: {
                      height: "432px",
                      autoplay: true,
                      arrow: "always",
                      "indicator-position": "none"
                    }
                  },
                  _vm._l(_vm.bannerList, function(item) {
                    return _c(
                      "el-carousel-item",
                      { key: item },
                      [
                        _c("el-image", {
                          staticClass: "banner-image web-banner-image",
                          attrs: { fit: "cover", src: item.main_figure_link },
                          on: {
                            click: function($event) {
                              return _vm.clickBanner(item)
                            }
                          }
                        }),
                        _c("div", { staticClass: "image-banner-title" }, [
                          _vm._v(_vm._s(item.title))
                        ])
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c("div", { staticClass: "tabs" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clicked("星存证")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "tab-item tab-proof" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/btnxingcunzheng.png")
                            }
                          })
                        ]),
                        _c("div", { staticClass: "tab-name" }, [
                          _vm._v("星存证")
                        ]),
                        _vm._m(0)
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clicked("星检索")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "tab-item tab-search" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/btnxingjiansuo.png")
                            }
                          })
                        ]),
                        _c("div", { staticClass: "tab-name" }, [
                          _vm._v("星检索")
                        ]),
                        _vm._m(1)
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clicked("星俊才")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "tab-item tab-elite" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/btnxingjuncai.png")
                            }
                          })
                        ]),
                        _c("div", { staticClass: "tab-name" }, [
                          _vm._v("星俊才")
                        ]),
                        _vm._m(2)
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clicked("星助理")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "tab-item tab-assistant" }, [
                        _c("div", [
                          _c("img", {
                            attrs: { src: require("@/assets/btnxingzhuli.png") }
                          })
                        ]),
                        _c("div", { staticClass: "tab-name" }, [
                          _vm._v("星助理")
                        ]),
                        _vm._m(3)
                      ])
                    ]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clicked("星代理")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "tab-item tab-delegate" }, [
                        _c("div", [
                          _c("img", {
                            attrs: { src: require("@/assets/btnxingdaili.png") }
                          })
                        ]),
                        _c("div", { staticClass: "tab-name" }, [
                          _vm._v("星代理")
                        ]),
                        _vm._m(4)
                      ])
                    ]
                  )
                ])
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator" }, [
      _c("i", { staticClass: "el-icon-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator" }, [
      _c("i", { staticClass: "el-icon-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator" }, [
      _c("i", { staticClass: "el-icon-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator" }, [
      _c("i", { staticClass: "el-icon-right" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator" }, [
      _c("i", { staticClass: "el-icon-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }