var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-mobile-navbar" },
    [
      _c("div", { staticClass: "fixed", attrs: { id: "mobile-nav-header" } }, [
        _c("div", { staticClass: "fixed-content" }, [
          _c(
            "div",
            { staticClass: "header-title" },
            [
              _c("el-image", { attrs: { src: require("@/assets/hnlogo.svg") } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "nav-menu" },
            [
              _c("el-button", {
                staticClass: "menu-button",
                attrs: { icon: "el-icon-menu" },
                on: {
                  click: function($event) {
                    _vm.drawer = true
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "fixed-bottom" }, [
        _vm.navbar.menuTop
          ? _c("div", { staticClass: "scroll-banner" }, [
              _c(
                "div",
                { staticClass: "mobile-banner" },
                [
                  _c(
                    "el-carousel",
                    {
                      staticClass: "banner-carousel",
                      attrs: { arrow: "always" }
                    },
                    _vm._l(_vm.bannerList, function(item) {
                      return _c(
                        "el-carousel-item",
                        { key: item.id },
                        [
                          _c("el-image", {
                            staticClass: "banner-image",
                            attrs: { fit: "cover", src: item.main_figure_link },
                            on: {
                              click: function($event) {
                                return _vm.clickBanner(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c(
        "el-drawer",
        {
          staticClass: "nav-drawer",
          attrs: {
            visible: _vm.drawer,
            direction: _vm.direction,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "el-menu",
            {
              staticClass: "menu",
              attrs: {
                "default-active": _vm.activeIndex,
                mode: "vertical",
                collapse: _vm.collapse
              },
              on: { select: _vm.handleSelect }
            },
            [
              _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("首页")]),
              _c(
                "el-menu-item",
                { attrs: { index: "2" } },
                [
                  _c(
                    "el-dropdown",
                    {
                      staticClass: "dropdown-class",
                      on: { command: _vm.handleCommand }
                    },
                    [
                      _c("span", { staticClass: "user" }, [_vm._v("星服务")]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "dropdown-class",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        _vm._l(_vm.menus, function(menu) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: menu.caption,
                              attrs: { command: menu.command }
                            },
                            [_vm._v(_vm._s(menu.caption))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-menu-item", { attrs: { index: "6" } }, [
                _vm._v("业务说明")
              ]),
              _c("el-menu-item", { attrs: { index: "7" } }, [
                _vm._v("维权服务")
              ]),
              _c("el-menu-item", { attrs: { index: "4" } }, [
                _vm._v("新闻中心")
              ]),
              _c("el-menu-item", { attrs: { index: "5" } }, [
                _vm._v("政策法规")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }