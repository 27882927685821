import clampjs from '@/common/lib/clamp.js'
function clampEl(el, binding) {
    let option = binding.value || {clamp: 5};
    // console.log('update', value)
    let ort = el.getAttribute('originalText');
    if(ort) {
       el.innerHTML = ort;
    }
    let {original} = clampjs(el, option);
    el.setAttribute('originalText',original);
}
export default {
    inserted: clampEl,
    update(el, binding) {
         clampEl(el, binding)
    },
};
