<template>
   <div class="home-page">
      <homeNavbar class="home-nav"></homeNavbar>
      <homeMobileNav class="home-mobile-nav" />
      <div class="scroll-news-bg">
         <div class="scroll-news">
            <div class="scroll-left">
               <div class="back-word">
                  News 
               </div>
               <div class="title" :class="{active:this.scrollNewsType == 1}" @click="scrollNewsType = 1">
                  <!-- <i class="el-icon-arrow-right"></i> -->
                  <IconSvg :iconClass="'jiantou'" class-name="tip"></IconSvg>
                  通知
               </div>
               <div class="title" :class="{active:this.scrollNewsType == 2}" @click="scrollNewsType = 2">
                  <IconSvg :iconClass="'jiantou'" class-name="tip"></IconSvg>
                  政策
               </div>
            </div>
            <div class="scroll-right">
               <ul>
                  <li v-for="item in scrollNews" :key="item.id">
                     <span class="article-tip">置顶</span>
                     <span class="article-title"><a target="_blank" :href="parseHref(item)">{{item.title}}</a></span>
                     <span class="publish-time">{{ item.publish_time.slice(0, 10) }}</span>
                  </li>
                  <li class="view-all">
                     <a target="_blank" :href="`/hndip/news-list/${scrollNewsType}`">查看全部 ></a>
                  </li>
               </ul>
            </div>
         </div>
      </div>
      <div class="middle-system">
         <div class="sub-system system-statics">
            <div class="system-header">
               <div class="title">
                  <div></div>
                  <div>星存证</div>
               </div>
               <div class="image">
                  <el-image class="system-image" fit="cover" :src="require('@/assets/xingcunzheng.svg')"></el-image>
               </div>
            </div>
            <div>
               <div class="abstract">
                  中国（湖南）自由贸易试验区数字知识产权保护平台通过技术手段为任意数据生成不可篡改的证据，
                  证明数据在某个时间点存在，同时无需对外暴露数据。
                  具有保护隐私、低成本和快捷的优势，可以方便且安全地嵌入数据生产的流程中，
                  提供更细粒度的产权证明和制造过程存证。
                  数字知识产权保护在知识产权的前置确权阶段发挥作用，
                  为传统知识产权的确权和维权提供更有力的保障。
               </div>
               <div>
                  <a @click="config.middle_systems[0].visit(config.middle_systems[0].url)"><span class="primary btn">
                     进入平台 <span class="indicator">&nbsp;<i class="el-icon-right"></i></span>
                     </span></a>
                  <a target="_blank" :href="config.middle_systems[0].intro_url"><span class="btn">平台介绍
                     <span class="indicator">&nbsp;<i class="el-icon-right"></i></span>
                     </span></a>
               </div>
            </div>
            <div>

            </div>
         </div>
         <div class="sub-system system-search">
            <div class="system-header">
               <div class="title">
                  <div></div>
                  <div>星检索</div>
               </div>
               <div class="image">
                  <el-image class="system-image" fit="cover" :src="require('@/assets/xingjiansuo.svg')"></el-image>
               </div>
            </div>
            <div>
               <div class="abstract">
                  该系统涵盖分析子系统、法律检索模块、重点产业专利导航专题库，整合国内外专利、商标、版权等知识产权数据资源，实现数据检索、分析等多种功能，是国内领先的知识产权大数据应用系统，提供专利检索、商标检索、标准检索、版权检索、集成电路检索等功能，旨在为创新主体提供最优质、高效的知识产权信息服务。
               </div>
               <div>
                  <a @click="config.middle_systems[1].visit(config.middle_systems[1].url)"><span class="primary btn">进入平台
                     <span class="indicator">&nbsp;<i class="el-icon-right"></i></span>
                     </span></a>
               </div>
            </div>
         </div>
      </div>
      <div class="public-services">
         <div class="header">公共服务</div>
         <div class="public-systems">
            <div class="public-system" v-for="(itm,index) in config.public_services" :key="index">
               <a @click="itm.visit(itm.url)">
                  <div>
                     <img :src="itm.asset_url ? itm.asset_url : itm.img_url" />
                  </div>
                  <div class="intro">
                     <div class="name">{{itm.name}}</div>
                     <div class="paragraph" v-clamp="{clamp:4,truncationChar:`...`,useNativeClamp:true}">{{itm.abstract}}</div>
                  </div>
               </a>
            </div>
         </div>
      </div>
      <div class="zone-intro">
         <div class="zone-intro-content">
            <div class="zone-info">
               <div class="zone-title">中心简介</div>
               <div class="zone-desc">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国（湖南）自由贸易试验区知识产权服务中心隶属于长沙市经开区市场监督管理局（知识产权局），中心整合国家、省、市知识产权公共资源，打造集知识产权创造、运用、保护、管理、服务为一体的“一站式”服务平台。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中心以基础核心业务为支撑，建设国家知识产权海外纠纷应对指导中心长沙分中心、国家知识产权局专利局专利审查协作湖北中心长沙工作站、湖南省知识产权综合服务（长沙经开区）分中心、中国（湖南）自由贸易试验区长沙片区知识产权公共服务中心等重要平台。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中心下设综合管理部、信息服务部和维权援助部3个部门，共有工作人员17人，其中专利审查员4人，专利维权律师2人，专利代理师4人，商标代理师2人。常驻3家知识产权服务机构，7家流动知识产权服务机构。
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中心提供知识产权快速授权、快速确权、快速维权、运营导航等27项知识产权公共服务。实现省级知识产权服务事项办理不出园区，线上信息化平台链接全省服务资源。探索“知识产权保护+区块链”的应用场景，建立知识产权前置存证平台，实现企业知识产权全流程保护。
               </div>
               <div class="center-enter-div see-more" >
                  ”
               </div>
               <div class="view-more">
                  <router-link :to="districtIntro" target="_blank"><span class="primary btn">查看更多
                     <span class="indicator">&nbsp;<i class="el-icon-right"></i></span>
                     </span></router-link>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import NewsAPI from "@/api/v1/news";
import store from "@/store";
import homeNavbar from "@/views/index/home-navbar";
import homeMobileNav from "@/views/index/home-mobile-navbar";
import IconSvg from '@/views/IconSvg'
import config from '@/../config.js'
import clamp from "@/common/directives/clamp";
import {mapState} from 'vuex'
export default {
   name: "",
   components: { homeNavbar, homeMobileNav,IconSvg},
   data() {
      return {
         scrollTitles: [],
         scrollImages: [],
         notifications: [],
         scrollNewsType: 1,
         policies: [],
         systemNum: ["全链条", "高效率", "全球化"],
      };
   },
   directives: {
      clamp,
   },
   mounted() {
      this.getData();
   },
   computed: {
      ...mapState(['system']),
      config(){
         return config
      }, 
      proofIntro() {
         return this.$router.resolve({ name: "proofIntro" }).href;
      },
      districtIntro() {
         return this.$router.resolve({ name: "districtIntro" }).href;
      },
      scrollNews() {
         return this.scrollNewsType == 1 ? this.notifications : this.policies;
      },
   },
   methods: {
      getData() {
         NewsAPI.getList(0, 3, false, false, 1).then((response) => {
            this.notifications = response.list;
            console.log(this.notifications);
         });
         NewsAPI.getList(0, 3, false, false, 2).then((response) => {
            this.policies = response.list;
            console.log(this.policies);
         });
         NewsAPI.getImageBanner(0, 10).then((response) => {
            this.scrollImages = response.list;
         });
         NewsAPI.getTitleBanner(0, 10).then((response) => {
            this.scrollTitles = response.list;
         });
      },
      sysBg(index) {
         if (index === 0) {
            return "sys-chain";
         } else if (index === 1) {
            return "sys-quick";
         } else if (index === 2) {
            return "sys-global";
         }
         return "";
      },
      marqueeLink(item) {
         return this.$router.resolve({
            name: "articleDetail",
            params: { from: 0, id: item.id },
         }).href;
      },
     goToDetail(params) {
       let article = params.article;
       this.$router.push({
         name: "articleDetail",
         params: { from: 0, id: article.id },
       });
     },
     parseHref(article) {
       let url;
       if (article.external_link) {
         url = article.external_link;
       } else {
         url = this.$router.resolve({
           name: "articleDetail",
           params: { from: 0, id: article.id },
         }).href;
       }
       return url;
     },
   },
   beforeRouteEnter(to, from, next) {
      store.commit("navbar/SET_POSITION_TOP");
      let pageIndex = { activeIndex: "1" };
      store.commit("navbar/SET_ACTIVEINDEX", pageIndex);
      next();
   },
};
</script>

<style lang="stylus" scoped>
.home-page {
   flex-grow: 1;
   flex-shrink: 0;
   width: 100%;
}

.scroll-news-bg {
   height: auto;
   background: #FFF;

   .scroll-news {
      width: 100%;
      margin: 0 auto;
      height: auto;
      display: flex;
      flex-wrap: wrap;

      .scroll-left {
         
         padding-left:calc(50% - 600px)
         width:calc(50% - 352px)
         min-width:368px;
         height: 180px;
         display: flex;
         flex-direction: column;
         box-sizing: border-box
         justify-content: center;
         background: #F3F5FB;
         font-size: 24px;
         font-weight: 500;
         color: #2C53B0;
         line-height: 48px;
         position relative
         .el-icon-arrow-right {
            font-size: 14px;
            visibility: hidden;
            vertical-align: middle;
            font-weight: bold;
            margin-right: 15px;
         }
         .back-word {
            color white
            font-size 96px
            position absolute
            bottom 0px
            left 0px
            height 96px
            display flex
            align-items center
            pointer-events: none
            opacity:0.5
            font-family Helvetica
            font-weight bold
         }
         .title {
            line-height: 48px;
            cursor: pointer;
            z-index 1
            .tip {
               fill:#2C53B0;
               width 15px
               height 15px
               vertical-align middle
               visibility: hidden;
            }
            &.active {
               font-size: 32px;
               font-family: PingFang-SC-Bold, PingFang-SC;
               font-weight: bold;
               color: #2C53B0;

               .tip {
                 visibility: visible;
               }
            }
         }
      }

      .scroll-right {
         background: #FAFAFA;
         display: flex;
         align-items: center;
         min-width: 0px;
         height: 180px;
         flex 1
         ul {
            width: 100%;

            li {
               list-style-type: none;
               height: 32px;
               display: flex;
               margin-right: calc(50vw - 600px);

               .article-tip {
                  width: 36px;
                  height: 20px;
                  border: 1px solid #2D52AF;
                  font-weight: 300;
                  color: #2D52AF;
                  line-height: 20px;
                  font-size: 14px;
                  text-align: center;
                  margin-right: 20px;
               }

               .article-title {
                  flex: 1;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                  overflow: hidden;
                  margin-right: 20px;

                  a {
                     font-size: 16px;
                     font-weight: 500;
                     color: #222222;
                     line-height: 22px;
                     text-decoration: none;

                     &:hover {
                        text-decoration: underline;
                     }
                  }
               }

               .publish-time {
                  font-size: 16px;
                  font-weight: 300;
                  color: #999999;
                  line-height: 22px;
               }

               &.view-all {
                  a {
                     font-size: 16px;
                     font-weight: 500;
                     color: #2C53B0;
                     line-height: 22px;
                     cursor: pointer;
                     text-decoration: none;

                     &:hover {
                        text-decoration: underline;
                     }
                  }
               }
            }
         }
      }
   }

   .scroll-link {
      line-height: 62px;
      margin-right: 40px;
      font-size: 18px;
      color: #0F64A9;
      text-decoration: none;
   }
}

.marquee-item {
   display: inline;
}

.home-decoration {
   height: 72px;
   margin: 20px 0px 20px 0px;

   .home-decoration-container {
      height: 100%;
      position: relative;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .home-decoration-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
   }

   .home-decoration-text {
      font-size: 26px;
      font-weight: bold;
      color: #307EAF;
      z-index: 9;
   }
}

.btn {
   width: 160px;
   height: 44px;
   display: inline-block;
   border-radius: 2px;
   text-align: center;
   line-height: 44px;
   color: #2C53B0;
   font-size: 18px;
   font-weight: 800;
   border: 2px solid #2C53B0;
   cursor: pointer;
   box-sizing: content-box;

   &.primary {
      background: #2C53B0;
      border-radius: 2px;
      color: #fff;
      margin-right: 10px;
   }
   .indicator {
      font-size: 20px;
      font-weight: bold;
      display: none;
   }
   &:hover {
      .indicator {
         display: inline;
      }
   }
}

.middle-system {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 1200px;
   margin: 50px auto;
   border-top-right-radius: 100px;
   border-bottom-left-radius: 100px;
   overflow: hidden;

   .sub-system {
      flex: 1;
      width: 600px;
      height: 600px;
      padding-left: 60px;
      padding-right: 60px;

      .detail {
         margin-top: 16px;
      }

      .abstract {
         font-size: 16px;
         font-weight: 300;
         color: #222;
         line-height: 24px;
         margin-top: 16px;
         margin-bottom: 24px;
         height: 168px;
      }

      .system-header {
         height: 267px;

         > div:first-of-type {
            width: 230px;
            display: inline-block;
         }

         > div:nth-child(2) {
            width: 250px;
            display: inline-block;
         }

         .title {
            height: 100%;
            font-size: 32px;
            font-weight: bold;
            color: #222222;
            line-height: 40px;
            letter-spacing: 1px;

            > div:first-of-type {
               display: block;
               height: calc(100% - 40px);
            }
         }
      }

      &.system-statics {
         background: #F8F7FE;
      }

      &.system-search {
         background: #F0F3F8;
      }
   }

   .system-image {
      width: 250px;
      height: 230px;
   }

   .system-statics {
      display: flex;
      flex-direction: column;
   }

   .system-info {
      display: block;

      .system-enter-left {
         margin-right: 10px;
      }

      .system-enter-right {
         margin-left: 10px;
      }
   }
}

.public-services {
   height: auto;
   background: url('../../../assets/public-services-bg.png');
   background-size: cover;
   text-align: center;
   padding-top 34px;
   .header {
      font-size: 32px;
      font-weight: bold;
      color: #2C53B0;
      line-height: 48px;
      margin-bottom 30px
   }

   .public-systems {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px auto;
      height: 100%;
      flex-wrap: wrap;
      width 1260px
      .public-system {
         margin-right: 30px;
         height: 410px;
         width: 380px;
         cursor: pointer;
         background: #F3F5FB;
         margin-bottom: 58px;
         transition: 0.25s ease all;
         &:hover {
            transform:scale(1.05)
         }
         > div {
            height: 50%;
         }
         a {
            text-decoration: none
         }
         div:first-of-type {
            img {
               width: 100%;
               height: 100%;
            }
         }

         .intro {
            text-align: left;
            padding-left: 28px;
            padding-right: 32px;

            .name {
               font-weight: bold;
               color: #222222;
               line-height: 48px;
               font-size: 24px;
            }

            .paragraph {
               font-size: 16px;
               font-weight: 300;
               color: #222;
               line-height: 24px;
               width: 320px;
               height: 96px;
            }
         }
      }
   }
}

@media only screen and (min-width: 600px) {
   .home-mobile-nav {
      display: none;
      height: 0px;
   }

   .home-decoration-container {
      width: 1200px;
   }

   .statics-container {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 24px 0px;
      margin-left: -50px;

      .statics {
         display: block;
      }

      &.center {
         margin-left: 100px;
      }
   }

   .statics-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .system-title {
      font-size: 17px;
      font-weight: bold;
      color: #0F64A9;
      margin: 30px 30px 0px 30px;
   }

   .system-intro {
      font-size: 15px;
      line-height: 26px;
      color: #818181;
      margin: 10px 30px 0px 30px;
   }

   .system-enter {
      flex-grow: 1;
      height: 30px;
      color: #0F64A9;
      background: rgba(15, 100, 169, 0.2);
      border: 2px solid #0F64A9;
      font-weight: bold;
      border-radius: 18px;
      display: block;
      text-align: center;
      line-height: 30px;
      text-decoration: none;
   }

   .enter-div {
      display: flex;
      justify-content: center;
      margin: 10px 30px;
   }

   .zone-intro {
      background: #FFF;
   }

   .zone-intro-content {
      width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0px auto;
      margin-bottom: 0px;
      padding-top: 45px;
      padding-bottom: 67px;

      .zone-image {
         width: 400px;
         height: 452px;
         flex-shrink: 0;
      }

      .zone-info {
         margin: 0px 20px 0px 33px;
         width: 100%;
         flex-grow: 1;
      }

      .zone-desc {
         font-size: 16px;
         font-weight: 300;
         color: #222;
         line-height: 24px;
         text-align: left;
      }

      .zone-title {
         margin-bottom: 27px;
         font-weight: bold;
         color: #2C53B0;
         line-height: 48px;
         font-size: 32px;
         text-align: center;
      }

      .center-enter-div {
         display: flex;
         justify-content: flex-end;
      }

      .see-more {
         font-size: 32px;
         font-weight: bold;
         color: #0F64A9;
         text-decoration: none;
      }
      .view-more {
         text-align: center
         .btn {
            width 160px
            height 44px
            font-size: 18px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 44px;
         }
      }
   }

   .color {
      background: white;
      height: 80px;
   }

   .portal-info-bg {
      height: 136px;
      background: #F1F0F0;

      .portal-info {
         margin: 0 auto;
         display: flex;
         justify-content: space-between;
         width: 600px;
      }
   }

   .left-link {
      margin-top: 20px;
      display: flex;
   }

   .friendly-tip {
      margin-right: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #565656;
   }

   .select-container {
      display: flex;
      flex-direction: column;
   }

   .about {
      margin-top: 20px;

      .about-text {
         font-size: 20px;
         font-weight: bold;
         color: #565656;
         margin-bottom: 23px;
      }

      .about-link {
         font-size: 14px;
         font-weight: bold;
         color: #818181;
         margin-right: 20px;
         text-decoration: none;
      }
   }

   .select-container {
      /deep/.el-input__inner {
         font-size: 10px;
         height: 32px;
      }

      /deep/.el-input__icon {
         height: 36px;
      }
   }

   .power {
      height: 56px;
      background: #0F64A9;
      display: flex;
      align-items: center;

      .power-text {
         margin: 0 auto;
         font-size: 14px;
         font-weight: bold;
         color: white;
      }
   }
}

// mobile
@media only screen and (max-width: 600px) {
   .home-nav {
      display: none;
   }

   .home-decoration-container {
      width: 360px;
   }

   .scroll-news-bg {
      padding-left: 10px;
      padding-right: 10px;
      width 100vw;
      box-sizing border-box
      .scroll-news {
         width: 100% !important;
         flex-wrap: wrap;

         .scroll-left {
            width: 100% !important;
         }

         .scroll-right {
            flex: none !important;
            width: 100% !important;

            ul {
               box-sizing: border-box;

               li {
                  margin-right: 20px !important;
               }
            }
         }
      }
   }

   .middle-system {
      width: 100vw;
      flex-wrap: wrap;
      display: block;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      .sub-system {
         flex: 1;
         overflow: hidden;
         width: calc(100% - 120px);
         height: auto;
         padding-left: 60px;
         padding-right: 60px;
         padding-bottom: 50px;

         .detail {
            margin-top: 16px;
         }

         .abstract {
            font-size: 16px;
            font-weight: 300;
            color: #222;
            line-height: 24px;
            margin-top: 16px;
            margin-bottom: 24px;
            height: auto;
         }

         .system-header {
            height: auto;

            .title {
               margin-top: 20px;
               height: auto;

               > div:first-of-type {
                  display: none;
               }
            }

            > div:nth-child(2) {
               width: 100%;
               display: block;
            }
         }

         .btn {
            margin-top: 10px;
         }
      }

      .system-image {
         width: 250px;
         height: 230px;
      }

      .system-statics {
         display: block;
         flex-direction: column;
      }

      .system-header {
         height: auto;

         .title {
         }
      }
   }

   .public-systems {
      width: 100vw !important;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      .public-system {
         width: 100% !important;
         margin: auto !important;

         .paragraph {
            width: 100% !important;
         }
      }
   }

   .statics-container {
      flex-grow: 1;
      width: 40%;

      .sys-num {
         font-size: 24px;
         font-weight: bold;
         color: #0F64A9;
      }

      .sys-key {
         font-size: 16px;
         color: #0F64A9;
      }
   }

   [class^='center'] {
      margin-left: 20px;
   }

   .statics left {
      border-right: 1px solid black;
   }

   .statics-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      font-size: 26px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .system-title {
      font-size: 20px;
      font-weight: bold;
      color: #0F64A9;
      margin: 30px 30px 0px 30px;
   }

   .system-intro {
      font-size: 14px;
      line-height: 26px;
      color: #4E4E4E;
      margin: 14px 30px 12px 30px;
   }

   .enter-div {
      margin: 0px 30px 20px 30px;
      display: flex;
      justify-content: center;
   }

   .system-enter {
      width: 208px;
      height: 30px;
      color: #0F64A9;
      background: rgba(15, 100, 169, 0.2);
      border: 2px solid #0F64A9;
      font-weight: bold;
      border-radius: 18px;
      display: block;
      text-align: center;
      line-height: 30px;
      text-decoration: none;
   }

   .zone-intro {
      background: #F1F0F0;
   }

   .zone-intro-content {
      padding: 27px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .zone-img {
         display: flex;
         justify-content: center;
      }

      .zone-info {
         width: 100%;
      }

      .zone-desc {
         width: 100%;
         font-size: 14px;
         line-height: 26px;
         color: #818181;
      }

      .zone-title {
         margin: 30px 0px 14px 0px;
         font-size: 20px;
         font-weight: bold;
         color: #0F64A9;
      }

      .center-enter-div {
         display: flex;
         justify-content: flex-end;
      }

      .see-more {
         margin-top: 17px;
         font-size: 16px;
         font-weight: bold;
         color: #0F64A9;
         text-decoration: none;
      }
   }

   .portal-info-bg {
      background: #F1F0F0;

      .portal-info {
         margin-left: 20px;
         padding-bottom: 20px;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
      }
   }

   .left-link {
      margin-top: 20px;
      display: flex;
   }

   .friendly-tip {
      margin-right: 26px;
      font-size: 20px;
      font-weight: bold;
      color: #565656;
   }

   .select-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-right: 20px;
   }

   .about {
      margin-top: 20px;

      .about-text {
         font-size: 20px;
         font-weight: bold;
         color: #565656;
         margin-bottom: 23px;
      }

      .about-link {
         font-size: 14px;
         font-weight: bold;
         color: #818181;
         margin-right: 20px;
         text-decoration: none;
      }
   }

   .select-container {
      /deep/.el-input__inner {
         font-size: 14px;
         height: 44px;
         text-align: center;
      }

      /deep/.el-input__icon {
         height: 44px;
      }
   }

   .power {
      height: 56px;
      background: #0F64A9;
      display: flex;
      align-items: center;

      .power-text {
         margin: 0px 20px;
         font-size: 14px;
         font-weight: bold;
         color: white;
         text-align: center;
      }
   }
}
</style>