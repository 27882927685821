<template>
  <div class="home-mobile-navbar">
    <div class="fixed" id="mobile-nav-header">
      <div class="fixed-content">
        <div class="header-title">
          <el-image class="" :src="require('@/assets/hnlogo.svg')"></el-image>
        </div>
        <div class="nav-menu">
          <el-button class="menu-button" icon="el-icon-menu" @click="drawer = true"></el-button>
        </div>
      </div>
    </div>
    
    <div class="fixed-bottom">
      <div class="scroll-banner" v-if="navbar.menuTop">
        <div class="mobile-banner">
          <el-carousel class="banner-carousel" arrow="always">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
              <el-image class="banner-image" fit="cover" :src="item.main_figure_link" @click="clickBanner(item)"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <el-drawer class="nav-drawer" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <el-menu class="menu" :default-active="activeIndex" mode="vertical" :collapse="collapse" @select="handleSelect">
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">
          <el-dropdown class="dropdown-class" @command="handleCommand">
            <span class="user">星服务</span>
            <el-dropdown-menu class="dropdown-class" slot="dropdown">
               <el-dropdown-item  v-for="menu in menus" :key="menu.caption" :command="menu.command">{{menu.caption}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item index="6">业务说明</el-menu-item>
        <el-menu-item index="7">维权服务</el-menu-item>
        <el-menu-item index="4">新闻中心</el-menu-item>
        <el-menu-item index="5">政策法规</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import NewsAPI from '@/api/v1/news'
import {MenuItems} from "@/../config.js"
export default {
  name: "",
  data() {
    return {
      collapse: true,
      bannerList: [],
      drawer: false,
      direction: 'rtl',
      navScrollToTop: false,
    };
  },
  computed: {
    ...mapState(['navbar']),
    ...mapState({
      activeIndex: state => { return state.navbar.activeIndex },
    }),
    menus(){
         return MenuItems
    },
    showColorBg() {
      return this.activeIndex === '6'
    },
    imageHeight() {
      return this.navbar.menuTop ? '331px' : '200px'
    },
    
    fixedBgColor() {
      console.log('fixedBgColor');
      return this.navbar.menuTop ? '#00000000' : 'linear-gradient(0deg, #0F64A9, #0C548E)'
    },
    navImage() {
      if (this.activeIndex === '6') {
        return window.screen.width >= 600 ? 'centerpic' : 'mobile_center_pic'
      }
      else {
        return window.screen.width >= 600 ? 'headerbanner' : 'mobile_headerbanner'
      }
    },
    solidFit() {
      if (window.screen.width > 600) {
        return 'fill'
      }
      else {
        return 'cover'
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      NewsAPI.getImageBanner().then((res) => {
        this.bannerList = res.list
      })
    },
    // touch event 
    handleClose(done) {
      done();
    },
    handleSelect(key) {
      this.drawer = false
      if(key === "7") {
         this.$router.push({ name: "service" });
      } else if (key === '1') {
        this.$router.push({ name: 'home' })
        this.$store.commit('navbar/SET_POSITION_TOP')
      }
      else if (key === '2') {
        if (window.screen.width < 600) {
          this.drawer = true
        }
        return
      } else if (key === "6") {
        this.$router.push({ name: "business" });
      } else {
        let path = {'3': 1, '4': 3, '5': 2, '6': 6}
        let index = path[key]
        this.$router.push({ name: 'newsList', params: { type: index }})
      }
    },
    clickBanner(item) {
      let url
      if (item.external_link) {
        url = item.external_link
      } else {
        url = this.$router.resolve({name: 'articleDetail', params: { from: 0, id: item.id }}).href;
      }
      window.open(url, '_blank');
    },
    handleCommand(command) {
      if(command.callback) {
         command.callback()
      }
    },
  },
};
</script>

<style lang="stylus" scoped>

// mobile


@media only screen and (max-width: 600px) {
    
.home-mobile-navbar {
  height 100%
  display flex
  flex-direction column
}

.fixed {
  position fixed
  width 100%
  z-index 99
  background-color #2c53b0
  transition background-color 0.3s linear

  &.scrolled {
    background-color #2c53b0
  }
}

.menu-button {
  background rgba(0, 0, 0, 0)
  border none
  color white
}

/deep/.el-icon-menu {
  font-size 30px
}

.fixed-bottom {
  padding-top 94px
  padding-left 10px
  padding-right 10px
}

.fixed-content {
  height 94px
  font-size 22px

  display flex
  justify-content space-between
  align-items center
  flex-shrink 0

  .header-title {
    font-size 20px
    color #0F64A9
    margin-left 15px 
  }
}

.banner-carousel {
  height 231px

  /deep/.el-carousel__container {
    height 100%
  }

  /deep/.el-carousel__arrow {
    width: 40px;
    height: 40px;
    background: rgba(15, 100, 169, 0.3);
    border: 3px solid #0F64A9;
    border-radius: 50%;
  }
  /deep/.el-icon-arrow-left,
  /deep/.el-icon-arrow-right {
    font-size 36px
    color #0F64A9
  }

  /deep/.el-carousel__arrow--left {
    left 30px
  }
  /deep/.el-carousel__arrow--right {
    right 30px
  }
}

.mobile-banner {
  display block
}

.banner-image {
  width 100%
  height 100%
  cursor pointer
}

.menu-container {
  width 100%
  display none
}

.el-menu--collapse>.el-menu-item span, .el-menu--collapse>.el-submenu>.el-submenu__title span {
  visibility visible
  display inline
}

.el-menu {
  border-right none
}
}

</style>