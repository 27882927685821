<template>
  <div class="home-navbar">
    <div class="background">
      <img :src="require('@/assets/header-bg.png')" />
    </div>
    <div class="fixed" id="nav-header">
      <div class="fixed-content">
        <div class="header-title">
          <router-link :to="{ name: 'home' }">
            <el-image
              class="logo-image"
              :src="require('@/assets/hnlogo.svg')"
            ></el-image>
          </router-link>
          <el-input class="search" placeholder="搜索" v-model="searchV">
            <template slot="prefix">
              <span
                class="search-prefix"
                style="line-height: 40px; margin-left: 21px; color: white"
              >
                <i class="el-icon-search"></i>
              </span>
            </template>
          </el-input>
        </div>
        <div class="menu-container">
          <el-menu
            class="menu"
            :default-active="activeIndex"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2" :class="{ 'is-hover': dropdownVisible }">
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                @visible-change="
                  (val) => {
                    this.dropdownVisible = val;
                  }
                "
                placement="bottom"
              >
                <span class="user">星服务</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in menus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item
              index="6"
              :class="{ 'is-hover': businessDropdownVisible }"
            >
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                @visible-change="
                  (val) => {
                    this.businessDropdownVisible = val;
                  }
                "
                placement="bottom"
              >
                <span class="user">业务说明</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in businessMenus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>

            <el-menu-item
              index="7"
              :class="{ 'is-hover': rightDropdownVisible }"
            >
              <el-dropdown
                class="dropdown-class"
                @command="handleCommand"
                @visible-change="
                  (val) => {
                    this.rightDropdownVisible = val;
                  }
                "
                placement="bottom"
              >
                <span class="user">维权服务</span>
                <el-dropdown-menu class="dropdown-class" slot="dropdown">
                  <el-dropdown-item
                    v-for="menu in rightMenus"
                    :key="menu.caption"
                    :command="menu.command"
                    >{{ menu.caption }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
            <el-menu-item index="4">新闻中心</el-menu-item>
            <el-menu-item index="5">政策法规</el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>

    <div class="fixed-bottom">
      <div class="scroll-banner" v-if="navbar.menuTop">
        <div class="web-banner">
          <el-carousel
            :height="'432px'"
            class="carousel"
            :autoplay=true
            arrow="always"
            :indicator-position="'none'"
          >
            <el-carousel-item v-for="item in bannerList" :key="item">
              <el-image
                class="banner-image web-banner-image"
                fit="cover"
                :src="item.main_figure_link"
                @click="clickBanner(item)"
              ></el-image>
              <div class="image-banner-title">{{ item.title }}</div>
            </el-carousel-item>
          </el-carousel>
          <div class="tabs">
            <a @click="clicked('星存证')">
              <div class="tab-item tab-proof">
                <div>
                  <img :src="require('@/assets/btnxingcunzheng.png')" />
                </div>
                <div class="tab-name">星存证</div>
                <div class="indicator">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </a>
            <a @click="clicked('星检索')">
              <div class="tab-item tab-search">
                <div>
                  <img :src="require('@/assets/btnxingjiansuo.png')" />
                </div>
                <div class="tab-name">星检索</div>
                <div class="indicator">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </a>
            <a @click="clicked('星俊才')">
              <div class="tab-item tab-elite">
                <div>
                  <img :src="require('@/assets/btnxingjuncai.png')" />
                </div>
                <div class="tab-name">星俊才</div>
                <div class="indicator">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </a>
            <a @click="clicked('星助理')">
              <div class="tab-item tab-assistant">
                <div>
                  <img :src="require('@/assets/btnxingzhuli.png')" />
                </div>
                <div class="tab-name">星助理</div>
                <div class="indicator">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </a>
            <a @click="clicked('星代理')">
              <div class="tab-item tab-delegate">
                <div>
                  <img :src="require('@/assets/btnxingdaili.png')" />
                </div>
                <div class="tab-name">星代理</div>
                <div class="indicator">
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NewsAPI from "@/api/v1/news";
import { MenuItems, RightMenuItems, BusinessMenuItems } from "@/../config.js";
// import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: "",
  //   components: { Carousel3d, Slide },
  data() {
    return {
      collapse: true,
      bannerList: [],
      drawer: false,
      direction: "rtl",
      navScrollToTop: false,
      searchV: "",
      dropdownVisible: false,
      rightDropdownVisible: false,
      businessDropdownVisible: false,
    };
  },
  computed: {
    ...mapState(["navbar"]),
    ...mapState({
      activeIndex: (state) => {
        return state.navbar.activeIndex;
      },
      token: (state) => {
        return state.system.token;
      },
    }),
    menus() {
      return MenuItems;
    },
    rightMenus() {
      return RightMenuItems;
    },
    businessMenus() {
      return BusinessMenuItems;
    },
    showColorBg() {
      return this.activeIndex === "6";
    },
    imageHeight() {
      return this.navbar.menuTop ? "331px" : "200px";
    },

    fixedBgColor() {
      console.log("fixedBgColor");
      return this.navbar.menuTop
        ? "#00000000"
        : "linear-gradient(0deg, #0F64A9, #0C548E)";
    },
    solidFit() {
      if (window.screen.width > 600) {
        return "fill";
      } else {
        return "cover";
      }
    },
  },
  mounted() {
    this.getData();
    var warper = document.getElementById("app-warper");
    warper.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    var warper = document.getElementById("app-warper");
    warper.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    clicked(name) {
      let menuCap = MenuItems.find((itm) => itm.caption == name);
      if (menuCap) {
        menuCap.command.callback();
      }
    },
    getData() {
      NewsAPI.getImageBanner().then((res) => {
        this.bannerList = res.list;
      });
    },
    // touch event
    handleScroll(event) {
      let offsetY = event.target.scrollTop;
      var headerMain = document.getElementById("nav-header");
      if (offsetY >= 100) {
        if (!this.navScrollToTop) {
          headerMain.className = "fixed scrolled";
        }
        this.navScrollToTop = true;
      } else {
        if (this.navScrollToTop) {
          headerMain.className = "fixed";
        }
        this.navScrollToTop = false;
      }
    },
    handleClose(done) {
      done();
    },
    handleSelect(key) {
      this.drawer = false;
      if (key === "7") {
        this.$router.push({ name: "service" });
      } else if (key === "1") {
        this.$router.push({ name: "home" });
        this.$store.commit("navbar/SET_POSITION_TOP");
      } else if (key === "2") {
        if (window.screen.width < 600) {
          this.drawer = true;
        }
        return;
      } else if (key === "6") {
        this.$router.push({ name: "business" });
      } else {
        let path = { 3: 1, 4: 3, 5: 2 };
        let index = path[key];
        this.$router.push({ name: "newsList", params: { type: index } });
      }
    },
    clickBanner(item) {
      let url;
      if (item.external_link) {
        url = item.external_link;
      } else {
        url = this.$router.resolve({
          name: "articleDetail",
          params: { from: 0, id: item.id },
        }).href;
      }
      window.open(url, "_blank");
    },
    handleCommand(command) {
      if (command.callback) {
        command.callback();
      } else {
        this.$router.push({ name: command });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.home-navbar {
  height: 667px;
  display: flex;
  flex-direction: column;
  position: relative;

  .background {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, #2C53B0 0%, #87AEE0 100%);

    img {
      width: 1070px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  #nav-header {
    height: 131px;
  }
}

.nav-bgimage {
  background: #2b7bae;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.8;
}

/deep/.el-image__placeholder {
  background: #2b7bae;
}

.fixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s linear;

  &.scrolled {
    background: linear-gradient(180deg, #2C53B0 0%, #87AEE0 200%);
  }
}

.fixed-bottom {
  height: 432px;
  position: absolute;
  bottom: 69px;
  width: 100%;
}

.fixed-content {
  height: 131px;
  width: 1200px;
  margin: auto;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-shrink: 0;
  // flex-grow: 0;
  .header-title {
    flex-shrink: 0;
    height: 50%;
  }

  .nav-drawer {
    display: none;
  }
}

.logo-image {
  height: 47px;
  margin-top: 19px;
}

.menu-container {
  height: 50%;
  flex-grow: 0;
  display: flex;

  .menu {
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    background: #ffffff00;
    align-items: center;
  }

  .el-menu-item {
    text-align: center;
    background: #ffffff00;
    color: white;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
    margin: 0px 5px;

    &:hover {
      background: rgba(255, 255, 255, 0.16);
      height: 36px;
      color: white;
      font-weight: 800;

      .el-dropdown {
        color: white;
        font-weight: 800;
      }
    }

    &.is-hover {
      background: rgba(255, 255, 255, 0.16);
      height: 36px;
      color: white;
      font-weight: 800;
    }
  }

  .el-menu-item.is-active {
    background: #ffffff;
    font-weight: 800;
    color: #2C53B0;
    border-radius: 6px;
    border-bottom: none;

    .el-dropdown {
      color: #2C53B0;
    }
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
}

.el-menu-item * {
  vertical-align: baseline;
}

.el-dropdown {
  width: 100%;
  height: 100%;
  color: white;
  // &:hover {
  // color: #2C53B0
  // font-weight: 800;
  // }
}

.user {
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 18px;
}

.scroll-banner {
  height: 100%;
}

.web-banner {
  height: 432px;
  display: flex;
  width: 1200px;
  align-items: center;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;

  .tabs {
    width: 240px;
    height: 100%;
    display: flex;
    z-index: 1;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    .tab-item {
      flex: 1;
      display: flex;
      align-items: center;
      cursor: pointer;

      div:first-of-type {
        width: 48px;
        height: 48px;
        margin: 19px 9px 19px 29px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .tab-name {
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
        flex: 1;
      }

      .indicator {
        margin-right: 27px;
        font-size: 20px;
        color: #FFF;
        font-weight: bold;
        display: none;
      }

      &:hover {
        .indicator {
          display: block;
        }
      }
    }

    .tab-proof {
      background: #5574BF;
    }

    .tab-search {
      background: #6984C5;
    }

    .tab-elite {
      background: #7E96CE;
    }

    .tab-assistant {
      background: #92A6D4;
    }

    .tab-delegate {
      background: #A7B6DB;
    }
  }
}

.carousel {
  width: 960px;
  height: 432px;

  .el-carousel__container {
    heigth: 100%;
  }

  &.el-carousel {
    /deep/.el-carousel__arrow {
      color: white;
      text-align: center;
      background: rgba(44, 83, 176, 0.5);
      border-radius: 50%;
      width: 50px;
      height: 50px;

      &:hover {
        background: #2C53B0;
      }

      i {
        font-size: 16px;
        line-height: 50px;
        font-weight: bold;
        display: block;
        margin: auto 0;
      }
    }
  }
}

.banner-image {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.image-banner-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  color: #fff;
  height: 70px;
  line-height: 70px;
}

.el-dropdown-menu__item {
  padding: 13px 10px 13px 10px;
  background: #2c53b0;
  color: white;
  font-size: 14px;
  height: fit-content;
  width: 140px;
  text-align: center;
  line-height: 24px;

  &:hover {
    background: #5370BE;
    color: white !important;
  }
}

.el-dropdown-menu {
  border: none;
  transform: translateY(4px);
  border-radius: 6px;
  overflow: hidden;
  background: transparent;
  margin-top: -2px !important;
  padding-top: 10px !important;

  li:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  box-shadow: none !important;
}

.dropdown-class {
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}

.carousel-3d-slider .carousel-3d-slide {
  opacity: 1;
  visibility: visible;
  border: none;
  border: 5px solid white;
  border-width: 5px !important;
}

/deep/.popper__arrow {
  display: none;
  top: 0 !important;
  border: none;
}

.el-popper {
  margin-top: -1px;
}

.search {
  float: right;
  width: 250px;
  margin-top: 22px;

  /deep/.el-input__inner {
    background: transparent;
    outline: none;
    color: white;
    height: 40px;
    border-radius: 20px;
    border: 2px solid white;
    padding-left: 42px;

    &::-webkit-input-placeholder {
      color: #fff;
    }
  }

  .search-prefix {
    line-height: 40px;
    margin-left: 21px;
    color: white;
    font-size: 16px;

    i {
      font-size: 16px;
      line-height: 40px;
      margin-right: 5px;
    }
  }
}
</style>