var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c("homeNavbar", { staticClass: "home-nav" }),
      _c("homeMobileNav", { staticClass: "home-mobile-nav" }),
      _c("div", { staticClass: "scroll-news-bg" }, [
        _c("div", { staticClass: "scroll-news" }, [
          _c("div", { staticClass: "scroll-left" }, [
            _c("div", { staticClass: "back-word" }, [_vm._v(" News ")]),
            _c(
              "div",
              {
                staticClass: "title",
                class: { active: this.scrollNewsType == 1 },
                on: {
                  click: function($event) {
                    _vm.scrollNewsType = 1
                  }
                }
              },
              [
                _c("IconSvg", {
                  attrs: { iconClass: "jiantou", "class-name": "tip" }
                }),
                _vm._v(" 通知 ")
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "title",
                class: { active: this.scrollNewsType == 2 },
                on: {
                  click: function($event) {
                    _vm.scrollNewsType = 2
                  }
                }
              },
              [
                _c("IconSvg", {
                  attrs: { iconClass: "jiantou", "class-name": "tip" }
                }),
                _vm._v(" 政策 ")
              ],
              1
            )
          ]),
          _c("div", { staticClass: "scroll-right" }, [
            _c(
              "ul",
              [
                _vm._l(_vm.scrollNews, function(item) {
                  return _c("li", { key: item.id }, [
                    _c("span", { staticClass: "article-tip" }, [
                      _vm._v("置顶")
                    ]),
                    _c("span", { staticClass: "article-title" }, [
                      _c(
                        "a",
                        {
                          attrs: { target: "_blank", href: _vm.parseHref(item) }
                        },
                        [_vm._v(_vm._s(item.title))]
                      )
                    ]),
                    _c("span", { staticClass: "publish-time" }, [
                      _vm._v(_vm._s(item.publish_time.slice(0, 10)))
                    ])
                  ])
                }),
                _c("li", { staticClass: "view-all" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "/hndip/news-list/" + _vm.scrollNewsType
                      }
                    },
                    [_vm._v("查看全部 >")]
                  )
                ])
              ],
              2
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "middle-system" }, [
        _c("div", { staticClass: "sub-system system-statics" }, [
          _c("div", { staticClass: "system-header" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c("el-image", {
                  staticClass: "system-image",
                  attrs: {
                    fit: "cover",
                    src: require("@/assets/xingcunzheng.svg")
                  }
                })
              ],
              1
            )
          ]),
          _c("div", [
            _c("div", { staticClass: "abstract" }, [
              _vm._v(
                " 中国（湖南）自由贸易试验区数字知识产权保护平台通过技术手段为任意数据生成不可篡改的证据， 证明数据在某个时间点存在，同时无需对外暴露数据。 具有保护隐私、低成本和快捷的优势，可以方便且安全地嵌入数据生产的流程中， 提供更细粒度的产权证明和制造过程存证。 数字知识产权保护在知识产权的前置确权阶段发挥作用， 为传统知识产权的确权和维权提供更有力的保障。 "
              )
            ]),
            _c("div", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.config.middle_systems[0].visit(
                        _vm.config.middle_systems[0].url
                      )
                    }
                  }
                },
                [_vm._m(1)]
              ),
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: _vm.config.middle_systems[0].intro_url
                  }
                },
                [_vm._m(2)]
              )
            ])
          ]),
          _c("div")
        ]),
        _c("div", { staticClass: "sub-system system-search" }, [
          _c("div", { staticClass: "system-header" }, [
            _vm._m(3),
            _c(
              "div",
              { staticClass: "image" },
              [
                _c("el-image", {
                  staticClass: "system-image",
                  attrs: {
                    fit: "cover",
                    src: require("@/assets/xingjiansuo.svg")
                  }
                })
              ],
              1
            )
          ]),
          _c("div", [
            _c("div", { staticClass: "abstract" }, [
              _vm._v(
                " 该系统涵盖分析子系统、法律检索模块、重点产业专利导航专题库，整合国内外专利、商标、版权等知识产权数据资源，实现数据检索、分析等多种功能，是国内领先的知识产权大数据应用系统，提供专利检索、商标检索、标准检索、版权检索、集成电路检索等功能，旨在为创新主体提供最优质、高效的知识产权信息服务。 "
              )
            ]),
            _c("div", [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.config.middle_systems[1].visit(
                        _vm.config.middle_systems[1].url
                      )
                    }
                  }
                },
                [_vm._m(4)]
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "public-services" }, [
        _c("div", { staticClass: "header" }, [_vm._v("公共服务")]),
        _c(
          "div",
          { staticClass: "public-systems" },
          _vm._l(_vm.config.public_services, function(itm, index) {
            return _c("div", { key: index, staticClass: "public-system" }, [
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return itm.visit(itm.url)
                    }
                  }
                },
                [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: itm.asset_url ? itm.asset_url : itm.img_url
                      }
                    })
                  ]),
                  _c("div", { staticClass: "intro" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(itm.name))
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "clamp",
                            rawName: "v-clamp",
                            value: {
                              clamp: 4,
                              truncationChar: "...",
                              useNativeClamp: true
                            },
                            expression:
                              "{clamp:4,truncationChar:`...`,useNativeClamp:true}"
                          }
                        ],
                        staticClass: "paragraph"
                      },
                      [_vm._v(_vm._s(itm.abstract))]
                    )
                  ])
                ]
              )
            ])
          }),
          0
        )
      ]),
      _c("div", { staticClass: "zone-intro" }, [
        _c("div", { staticClass: "zone-intro-content" }, [
          _c("div", { staticClass: "zone-info" }, [
            _c("div", { staticClass: "zone-title" }, [_vm._v("中心简介")]),
            _vm._m(5),
            _c("div", { staticClass: "center-enter-div see-more" }, [
              _vm._v(" ” ")
            ]),
            _c(
              "div",
              { staticClass: "view-more" },
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm.districtIntro, target: "_blank" } },
                  [
                    _c("span", { staticClass: "primary btn" }, [
                      _vm._v("查看更多 "),
                      _c("span", { staticClass: "indicator" }, [
                        _vm._v(" "),
                        _c("i", { staticClass: "el-icon-right" })
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div"),
      _c("div", [_vm._v("星存证")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "primary btn" }, [
      _vm._v(" 进入平台 "),
      _c("span", { staticClass: "indicator" }, [
        _vm._v(" "),
        _c("i", { staticClass: "el-icon-right" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "btn" }, [
      _vm._v("平台介绍 "),
      _c("span", { staticClass: "indicator" }, [
        _vm._v(" "),
        _c("i", { staticClass: "el-icon-right" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div"),
      _c("div", [_vm._v("星检索")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "primary btn" }, [
      _vm._v("进入平台 "),
      _c("span", { staticClass: "indicator" }, [
        _vm._v(" "),
        _c("i", { staticClass: "el-icon-right" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "zone-desc" }, [
      _vm._v(
        "         中国（湖南）自由贸易试验区知识产权服务中心隶属于长沙市经开区市场监督管理局（知识产权局），中心整合国家、省、市知识产权公共资源，打造集知识产权创造、运用、保护、管理、服务为一体的“一站式”服务平台。 "
      ),
      _c("br"),
      _vm._v(
        "         中心以基础核心业务为支撑，建设国家知识产权海外纠纷应对指导中心长沙分中心、国家知识产权局专利局专利审查协作湖北中心长沙工作站、湖南省知识产权综合服务（长沙经开区）分中心、中国（湖南）自由贸易试验区长沙片区知识产权公共服务中心等重要平台。 "
      ),
      _c("br"),
      _vm._v(
        "         中心下设综合管理部、信息服务部和维权援助部3个部门，共有工作人员17人，其中专利审查员4人，专利维权律师2人，专利代理师4人，商标代理师2人。常驻3家知识产权服务机构，7家流动知识产权服务机构。 "
      ),
      _c("br"),
      _vm._v(
        "         中心提供知识产权快速授权、快速确权、快速维权、运营导航等27项知识产权公共服务。实现省级知识产权服务事项办理不出园区，线上信息化平台链接全省服务资源。探索“知识产权保护+区块链”的应用场景，建立知识产权前置存证平台，实现企业知识产权全流程保护。 "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }